<template>
  <MainCardsBlock
    v-element-visibility="(isVisibility) => isVisibility && sendHomeShowShelf(label, {
      shelf_number: shelfNumber,
    })"
    title="Знания доступны каждому"
    description="Курсы до&nbsp;990&nbsp;₽. Цена маленькая, польза&nbsp;&mdash; большая"
    button-text="Все курсы до 990 ₽"
    button-link="/catalog/?price_max=990&price_min=1"
    :cards="coursesUnder1000"
    @click:card="(event: any) => {
      sendHomeClickShelf(label, {
        shelf_number: shelfNumber
      })
      sendHomeClickCard({
        shelf_number: shelfNumber,
        card_number: event.cardNumber,
        nomenclature_id: event.nomenclatureId,
        nomenclature_type: event.nomenclatureType,
      })
    }"
    @click:link="sendHomeClickLink(label, {
      link: '/catalog/?price_max=990&price_min=1',
    })"
    @visible:card="(event: any) => sendHomeShowCard({
      shelf_number: shelfNumber,
      card_number: event.cardNumber,
      nomenclature_id: event.nomenclatureId,
      nomenclature_type: event.nomenclatureType,
    })"
  />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { vElementVisibility } from '@vueuse/components'

  import { useMainStore } from '@/store/main'

  import { sendHomeShowShelf, sendHomeClickShelf, sendHomeClickCard, sendHomeClickLink, sendHomeShowCard } from '@/pages/index/analytics'

  const label = 'courses_under_1000'
  const shelfNumber = 2

  const mainStore = useMainStore()
  const { coursesUnder1000 } = storeToRefs(mainStore)

  useAsyncData(
    'getCoursesUnder1000',
    () => mainStore.getCoursesUnder1000(),
  )
</script>
